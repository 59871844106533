define("seasonalysis/models/pattern-count", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    count: _emberData.default.attr("number"),
    length: _emberData.default.attr("number")
  });
});
