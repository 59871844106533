define("seasonalysis/components/editable-label", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    renaming: false,

    actions: {
      toggleRename: function toggleRename() {
        var current = this.get("renaming");
        var updated = !current;
        this.set("renaming", updated);

        if (updated == true) {
          this.$("editable-text").focus();
        }
      },
      save: function save() {
        console.log("saving..");
      }
    }
  });
});
