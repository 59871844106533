define("seasonalysis/components/forms/reset-password", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    error: null,
    ajax: Ember.inject.service(),
    success: false,
    loading: false,

    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.$(".ui.form").form({
        fields: {
          password: ["minLength[8]", "empty"],
          confirmPassword: ["match[password]"]
        }
      });
    },


    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        var properties = this.getProperties("password");

        this.set("loading", true);
        this.get("ajax").request("auth/password/reset", {
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: {
            token: this.code,
            new_password: properties["password"]
          }
        }).then(function (_ref) {
          var response = _ref.response;

          _this.set("loading", false);
          _this.set("success", true);
        }).catch(function (_ref2) {
          var error = _ref2.error;

          _this.set("loading", false);
          if (error == undefined) {
            _this.set("error", "An unknown error occurred. Please try again.");
          } else if (error.error.detail != undefined) {
            _this.set("error", error.error.detail);
          } else {
            _this.set("error", "An unknown error occurred. Please try again.");
          }
          console.log("error", error);
        });
      }
    }
  });
});
