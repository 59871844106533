define("seasonalysis/components/secret-page", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    authManager: Ember.inject.service("session")
  });
});
