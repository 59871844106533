define("seasonalysis/components/charts/pattern-chart", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentPatternDetailAvailable: Ember.computed("pattern", function () {
      var currentPatternDetail = this.get("pattern.currentPatternDetail");
      if (currentPatternDetail === null || currentPatternDetail === undefined) {
        return false;
      }

      var currentGainLoss = currentPatternDetail.get("gainLoss");
      if (currentGainLoss === null || currentGainLoss === undefined) {
        return false;
      }

      return true;
    }),

    chartOptions: Ember.computed("pattern", function () {
      var performance = this.get("pattern.patternDetails");
      var yearsOfData = this.get("pattern.yearsOfData");
      var highlightModifier = 0;

      var categories = performance.map(function (a) {
        return a.get("year");
      });
      var gainLosses = performance.map(function (a) {
        return a.get("gainLoss");
      });

      if (this.get("currentPatternDetailAvailable") === true) {
        var currentPatternDetail = this.get("pattern.currentPatternDetail");
        var currentGainLoss = currentPatternDetail.get("gainLoss");

        categories.push(currentPatternDetail.get("year"));
        gainLosses.push(currentGainLoss);
        highlightModifier = 1;
      }

      var max = Math.max.apply(null, gainLosses);
      var min = Math.min.apply(null, gainLosses);
      var range = max - min;
      max += range * 0.1;
      // Only highlight pattern years
      return {
        chart: {
          type: "column"
        },
        title: {
          text: null
        },
        xAxis: {
          categories: categories,
          allowDecimals: false,
          plotBands: [{
            from: categories.length - 0.5 - yearsOfData - highlightModifier,
            to: categories.length + 0.5 - highlightModifier * 2,
            color: "rgba(20, 68, 179, 0.25)",
            label: {
              text: "Historical performance"
            }
          }]
        },
        yAxis: {
          title: {
            text: "% Change"
          },
          labels: {
            formatter: function formatter() {
              return this.value + "%";
            }
          },
          max: max
        },
        tooltip: {
          valueSuffix: "%",
          formatter: function formatter() {
            return "<b>" + this.x + "</b>: " + this.y + "%";
          }
        },
        legend: {
          enabled: false
        }
      };
    }),

    chartData: Ember.computed("pattern", function () {
      var performance = this.get("pattern.patternDetails");
      var gainLosses = performance.map(function (a) {
        return a.get("gainLoss");
      });

      if (this.get("currentPatternDetailAvailable") === true) {
        var currentPatternDetail = this.get("pattern.currentPatternDetail");
        var currentGainLoss = currentPatternDetail.get("gainLoss");
        gainLosses.push(currentGainLoss);
      }

      var data = [];
      for (var idx in gainLosses) {
        var color = function () {
          if (gainLosses[idx] >= 0) {
            return "rgba(33, 186, 69, 1.0)";
          }

          return "rgba(219, 40, 40, 1.0)";
        }();

        if (idx < gainLosses.length) {
          // Why do we need this?
          data.push({ color: color, y: gainLosses[idx] });
        }
      }

      var result = [{ data: data }];
      return result;
    })
  });
});
