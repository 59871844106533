define("seasonalysis/controllers/sign-up", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      openModal: function openModal(name) {
        $(".ui." + name + ".modal").modal("show");
      },

      approveModal: function approveModal(element, component) {
        alert("approve " + component.get("name"));
        return false;
      },

      denyModal: function denyModal(element, component) {
        alert("deny " + component.get("name"));
        return true;
      }
    }
  });
});
