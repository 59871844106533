define("seasonalysis/models/fragments/average-chart", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "moment"], function (exports, _fragment, _attr, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    name: (0, _attr.default)("string"),
    startDate: (0, _attr.default)("date"),
    chartData: (0, _attr.default)("array"),

    series: Ember.computed("startDate", "name", "chartData", function () {
      var startDate = this.get("startDate");

      var seriesData = {
        type: "line",
        name: this.get("name"),
        pointInterval: 24 * 3600 * 1000,
        pointStart: _moment.default.utc(startDate).valueOf(),
        data: this.get("chartData")
      };

      if (seriesData.name.includes("5 Year Average")) {
        seriesData.color = "Green";
      } else if (seriesData.name.length == 4) {
        seriesData.color = "#1444b3";
      } else {
        seriesData.color = "Red";
      }

      return seriesData;
    })
  });
});
