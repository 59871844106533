define("seasonalysis/components/nav-bar", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showLogoutConfirmalModal: false,

    init: function init() {
      this._super.apply(this, arguments);
      var appCtrl = Ember.getOwner(this).lookup("controller:application");
      this.set("appCtrl", appCtrl);
    },


    isSecurityBasketRoute: Ember.computed("appCtrl.currentRouteName", function () {
      var currentRouteName = this.get("appCtrl.currentRouteName");
      if (currentRouteName.includes("security-basket")) {
        return true;
      }

      return false;
    }),

    isEmailAlertsRoute: Ember.computed("appCtrl.currentRouteName", function () {
      var currentRouteName = this.get("appCtrl.currentRouteName");
      if (currentRouteName.includes("email-alerts")) {
        return true;
      }

      return false;
    }),

    isMyPatternsRoute: Ember.computed("appCtrl.currentRouteName", function () {
      var currentRouteName = this.get("appCtrl.currentRouteName");
      if (currentRouteName.includes("my-patterns")) {
        return true;
      }

      return false;
    }),

    isSettingsRoute: Ember.computed("appCtrl.currentRouteName", function () {
      var currentRouteName = this.get("appCtrl.currentRouteName");
      if (currentRouteName.includes("settings")) {
        return true;
      }

      return false;
    }),

    isDiscoverRoute: Ember.computed("appCtrl.currentRouteName", function () {
      var currentRouteName = this.get("appCtrl.currentRouteName");
      if (currentRouteName.includes("discover")) {
        return true;
      }

      return false;
    }),

    session: Ember.inject.service(),

    actions: {
      confirmLogout: function confirmLogout() {
        this.get("session").invalidate();
      },
      cancelLogout: function cancelLogout() {
        this.set("showLogoutConfirmalModal", false);
      }
    }
  });
});
