define("seasonalysis/serializers/application", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    keyForAttribute: function keyForAttribute(key) {
      // From http://emberjs.com/api/classes/Ember.String.html#method_underscore
      var converted = Ember.String.underscore(key);
      return converted;
    },

    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      var converted = Ember.String.underscore(modelName);
      return converted;
    }
  });
});
