define("seasonalysis/components/modals/base-modal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.element.remove();
    }
  });
});
