define("seasonalysis/adapters/pattern", ["exports", "seasonalysis/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(type) {
      var result = this._super(type);
      return result;
    },

    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, "query", _query);

      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }

      return this.ajax(url, "POST", { data: _query });
    }
  });
});
