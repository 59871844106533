define("seasonalysis/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    title: "Seasonalysis | Login",

    activate: function activate() {},
    redirect: function redirect() {
      if (this.get("session.isAuthenticated")) {
        // We are already logged in, no need to show the login page
        this.transitionTo("index");
      }
    },


    actions: {},

    afterModel: function afterModel() {
      //this.showLoginModalEnsuringState({onCloseDestinationRoute: '/'});
    }
  });
});
