define("seasonalysis/models/stock", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    ticker: _emberData.default.attr("string"),
    exchange: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    industry: _emberData.default.attr("string"),
    sector: _emberData.default.attr("string"),
    location: _emberData.default.attr("string")
  });
});
