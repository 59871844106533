define("seasonalysis/components/charts/spark-bar", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    chartOptions: Ember.computed("pattern", function () {
      return {
        chart: {
          // renderTo: (options.chart && options.chart.renderTo) || this,
          backgroundColor: null,
          borderWidth: 0,
          type: "column",
          margin: [2, 0, 2, 0],
          width: 110,
          height: 20,
          style: {
            overflow: "visible"
          },

          // small optimalization, saves 1-2 ms each sparkline
          skipClone: true
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [],
          lineWidth: 0,
          maxPadding: 0,
          tickLength: 20
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 0,
            //   pointWidth: 5,
            minPointLength: 3,
            shadow: false,
            states: {
              hover: {
                lineWidth: 0
              }
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            fillOpacity: 0.25
          },
          column: {
            negativeColor: "#910000",
            borderColor: "silver"
          }
        }
      };
    }),

    chartData: Ember.computed("data", function () {
      var result = this.get("data");
      return result;
    })
  });
});
