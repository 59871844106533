define("seasonalysis/helpers/format-float", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatFloat = formatFloat;
  function formatFloat(params /*, hash*/) {
    var number = params[0];
    var decimal = params[1];
    var suffix = params[2];
    var prefix = params[3];

    var formatted = parseFloat(number, 10).toFixed(decimal);
    if (suffix != undefined) {
      formatted = formatted + suffix;
    }

    if (prefix != undefined) {
      formatted = prefix + formatted;
    }

    return formatted;
  }

  exports.default = Ember.Helper.helper(formatFloat);
});
