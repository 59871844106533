define("seasonalysis/models/security-chart", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ember-data/relationships"], function (exports, _emberData, _attributes, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    security: (0, _relationships.belongsTo)("security", { inverse: null, async: false }),
    averageCharts: (0, _attributes.fragmentArray)("fragments/average-chart"),
    historicalSeasonality: (0, _attributes.fragmentArray)("fragments/seasonality-chart")
  });
});
