define("seasonalysis/components/modals/confirm-modal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      close: function close() {
        console.log("close called");

        this.set("show", false);
        this.get("onCancel")();
      },
      cancel: function cancel() {
        this.set("show", false);
        this.get("onCancel")();
      },
      confirm: function confirm() {
        this.set("show", false);
        this.get("onConfirm")();
      }
    }
  });
});
