define("seasonalysis/components/security-pattern-details", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      changePatternLength: function changePatternLength(newLength) {
        this.get("onLengthChanged")(newLength);
      },
      pageChanged: function pageChanged(newLength) {
        this.get("onPageChanged")(newLength);
      },
      sortChanged: function sortChanged(newLength) {
        this.get("onSortChanged")(newLength);
      }
    }
  });
});
