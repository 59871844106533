define("seasonalysis/services/session", ["exports", "ember-simple-auth/services/session", "ember-data"], function (exports, _session, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.computed("isAuthenticated", function () {
      console.log("current user", this.session.content.authenticated.user_id);
      if (this.get("isAuthenticated")) {
        var promise = this.get("store").findRecord("user", this.session.content.authenticated.user_id);
        return promise;
      }
    })
  });
});
