define("seasonalysis/components/modals/input-modal", ["exports", "seasonalysis/components/modals/base-modal"], function (exports, _baseModal) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModal.default.extend({
    classNames: ["ui", "mini", "modal"],
    classNameBindings: ["name"],
    loading: false,
    error: null,

    didRender: function didRender() {
      this._super.apply(this, arguments);
      $(".ui.form").form({
        fields: {
          input: ["empty"]
        }
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      console.log("didUpdateAttrs");
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      console.log("didReceiveAttrs");
    },


    actions: {
      onCancel: function onCancel() {
        this.get("onCancel")();
        this.$().modal("toggle");
        this.set("value", null);
      },
      onConfirm: function onConfirm() {
        this.get("onConfirm")(this.get("value"));
        this.$().modal("toggle");
        this.set("value", null);
      }
    }
  });
});
