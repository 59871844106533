define("seasonalysis/components/table-paginator", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "tr",

    lowerBound: Ember.computed("meta.pagination", function () {
      var meta = this.get("meta");
      var currentPage = meta.pagination.current_page;
      var pageSize = meta.pagination.page_size;

      var lowerBound = currentPage * pageSize - pageSize + 1;
      return lowerBound;
    }),

    upperBound: Ember.computed("meta.pagination", function () {
      var meta = this.get("meta");
      var currentPage = meta.pagination.current_page;
      var pageSize = meta.pagination.page_size;
      var total = meta.pagination.total;

      var upperBound = currentPage * pageSize;
      if (upperBound > total) {
        return total;
      }

      return upperBound;
    }),

    nextPageEnabled: Ember.computed("meta.pagination", function () {
      var meta = this.get("meta");
      var currentPage = meta.pagination.current_page;
      var pageCount = meta.pagination.page_count;

      if (currentPage < pageCount) {
        return true;
      }

      return false;
    }),

    lastPageEnabled: Ember.computed("meta.pagination", function () {
      var meta = this.get("meta");
      var currentPage = meta.pagination.current_page;
      var pageCount = meta.pagination.page_count;

      if (currentPage != pageCount) {
        return true;
      }

      return false;
    }),

    pageItems: Ember.computed("meta.pagination", function () {
      var pageItems = [];
      var maxPageItems = 5;
      var bounds = (maxPageItems - 1) / 2;
      var meta = this.get("meta");
      var currentPage = meta.pagination.current_page;
      var pageCount = meta.pagination.page_count;

      var useMiddleRange = currentPage - bounds >= 1 && currentPage + bounds <= pageCount;
      var useLowerRange = currentPage + bounds > pageCount && maxPageItems <= pageCount;
      var useUpperRange = currentPage + maxPageItems < pageCount;
      if (useMiddleRange) {
        for (var i = currentPage - bounds; i <= currentPage + bounds; i++) {
          var item = {
            pageNumber: i,
            enabled: i != currentPage
          };

          pageItems.push(item);
        }
      } else if (useLowerRange) {
        for (var _i = pageCount - maxPageItems; _i <= pageCount; _i++) {
          var _item = {
            pageNumber: _i,
            enabled: _i != currentPage
          };

          pageItems.push(_item);
        }
      } else if (useUpperRange) {
        for (var _i2 = currentPage; _i2 < currentPage + maxPageItems; _i2++) {
          var _item2 = {
            pageNumber: _i2,
            enabled: _i2 != currentPage
          };

          pageItems.push(_item2);
        }
      } else {
        for (var _i3 = currentPage; _i3 <= pageCount; _i3++) {
          var _item3 = {
            pageNumber: _i3,
            enabled: _i3 != currentPage
          };

          pageItems.push(_item3);
        }
      }

      return pageItems;
    }),

    previousPageEnabled: Ember.computed("meta.pagination", function () {
      var meta = this.get("meta");
      var currentPage = meta.pagination.current_page;

      if (currentPage != 1) {
        return true;
      }

      return false;
    }),

    firstPageEnabled: Ember.computed("meta.pagination", function () {
      var meta = this.get("meta");
      var currentPage = meta.pagination.current_page;

      if (currentPage != 1) {
        return true;
      }

      return false;
    }),

    actions: {
      changePage: function changePage(page) {
        this.get("onPageChanged")(page);
      }
    }
  });
});
