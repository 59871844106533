define("seasonalysis/routes/pattern-detail", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "seasonalysis/mixins/subscription"], function (exports, _authenticatedRouteMixin, _subscription) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _subscription.default, {
    titleToken: function titleToken(model) {
      return "Pattern | " + model.get("security.stock.name");
    },

    model: function model(params) {
      console.log("Fetching pattern detail model");
      return this.get("store").findRecord("pattern", params.id, {
        include: ["securities", "pattern_details", "security"],
        reload: true
      });
    },


    actions: {
      toggleFavorite: function toggleFavorite(pattern) {
        var favorited = pattern.get("favorited");
        pattern.set("favorited", !favorited);
        pattern.save();
      }
    }
  });
});
