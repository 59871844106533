define("seasonalysis/models/quote", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr"], function (exports, _fragment, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    date: (0, _attr.default)("date"),
    open: (0, _attr.default)("number"),
    high: (0, _attr.default)("number"),
    low: (0, _attr.default)("number"),
    close: (0, _attr.default)("number"),
    volume: (0, _attr.default)("number"),
    dividends: (0, _attr.default)("number")
  });
});
