define("seasonalysis/models/monthly-summary", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    month: _emberData.default.attr("string"),
    averageGainLoss: _emberData.default.attr("number"),
    averageGain: _emberData.default.attr("number"),
    averageLoss: _emberData.default.attr("number"),
    maxGain: _emberData.default.attr("number"),
    maxLoss: _emberData.default.attr("number"),
    up: _emberData.default.attr("number"),
    down: _emberData.default.attr("number"),
    standardDeviation: _emberData.default.attr("number"),

    upPercentange: Ember.computed("up", "down", function () {
      var precisionRound = function precisionRound(number, precision) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
      };

      var up = this.get("up");
      var down = this.get("down");
      var result = up / (up + down);
      return precisionRound(result * 100, 0);
    }),

    downPercentage: Ember.computed("up", "down", function () {
      var precisionRound = function precisionRound(number, precision) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
      };

      var up = this.get("up");
      var down = this.get("down");
      var result = down / (up + down);
      return precisionRound(result * 100, 0);
    })
  });
});
