define("seasonalysis/authorizers/application", ["exports", "ember-simple-auth/authorizers/oauth2-bearer"], function (exports, _oauth2Bearer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2Bearer.default.extend({
    session: Ember.inject.service(),
    authorize: function authorize(data, block) {
      block("X-Session-Id", "" + data.access_token);
    }
  });
});
