define("seasonalysis/routes/secret", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authManager: Ember.inject.service('session'),

    afterModel: function afterModel() {
      // console.log('stuff', this.get('session.currentUser'))
      //this.showLoginModalEnsuringState({onCloseDestinationRoute: '/'});
    }
  });
});
