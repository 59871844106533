define("seasonalysis/controllers/discover", ["exports", "ember-parachute"], function (exports, _emberParachute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.discoverParams = undefined;
  var discoverParams = exports.discoverParams = new _emberParachute.default({
    pageSize: {
      as: "page[size]",
      defaultValue: 50,
      refresh: true,
      replace: true
    },
    pageNumber: {
      as: "page",
      defaultValue: 1,
      refresh: true,
      replace: true
    }
  });

  exports.default = Ember.Controller.extend(discoverParams.Mixin, {
    queryParamsChanged: Ember.computed.or("queryParamsState.{pageSize,pageNumber}.changed"),

    setup: function setup(_ref) {
      var queryParams = _ref.queryParams;

      this.fetchData(queryParams);
    },
    queryParamsDidChange: function queryParamsDidChange(_ref2) {
      var shouldRefresh = _ref2.shouldRefresh,
          queryParams = _ref2.queryParams;

      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },
    reset: function reset(_ref3, isExiting) {
      var queryParams = _ref3.queryParams;

      if (isExiting) {
        this.resetQueryParams();
      }
    },
    fetchData: function fetchData(queryParams) {
      // fetch data
    },


    actions: {
      resetAll: function resetAll() {
        // reset all query params to their default values specified in the query param map
        this.resetQueryParams();
      }
    }
  });
});
