define("seasonalysis/services/ajax", ["exports", "ember-ajax/services/ajax", "seasonalysis/config/environment"], function (exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    namespace: _environment.default.API_NAMESPACE,
    host: _environment.default.API_HOST
  });
});
