define("seasonalysis/components/charts/pattern-detail-performance", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentPerformance: Ember.computed("pattern", function () {
      var quotes = this.pattern.get("currentPatternDetail.quotes");

      var currentPerformance = [];
      quotes.forEach(function (quote) {
        var day = {
          x: _moment.default.utc(quote.get("date")).toDate(),
          open: quote.get("open"),
          high: quote.get("high"),
          low: quote.get("low"),
          close: quote.get("close")
        };

        currentPerformance.push(day);
      });

      return currentPerformance;
    }),

    historicalPerformance: Ember.computed("pattern", function () {
      // return [32.6345, 32.0502, 31.3263, 31.1527, 31.2833];

      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var first = quotes.get("firstObject");
      var open = first.get("open");

      var averages = this.pattern.get("currentPatternDetail.averages");

      var historicalPerformance = [];

      for (var i in averages) {
        var dailyAverage = averages[i] / 100;
        var value = open * dailyAverage + open;
        if (isNaN(value) == false) {
          historicalPerformance.push(value);
        }
      }

      return historicalPerformance;
    }),

    chartOptions: Ember.computed("pattern", function () {
      var pattern = this.pattern;
      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var first = quotes.get("firstObject");
      var open = first.get("open");
      var minimumPrice = open;
      var maximumPrice = first.get("high");
      quotes.forEach(function (quote) {
        var low = quote.get("low");
        var high = quote.get("high");

        if (low < minimumPrice) {
          minimumPrice = low;
        }

        if (high > maximumPrice) {
          maximumPrice = high;
        }
      });

      var averageGainLossPrice = open * (1 + this.pattern.get("averageGainLoss") / 100);

      var gainLossLineColor = function () {
        var gainColor = "rgba(33, 186, 69, 1.0)";
        var lossColor = "rgba(219, 40, 40, 1)";
        if (pattern.get("averageGainLoss") >= 0) {
          return gainColor;
        }

        return lossColor;
      }();

      if (averageGainLossPrice < minimumPrice) {
        minimumPrice = averageGainLossPrice;
      }

      if (averageGainLossPrice > maximumPrice) {
        maximumPrice = averageGainLossPrice;
      }

      var chartOptions = {
        chart: {
          zoomType: "xy",
          alignTicks: false,
          margin: [40, 0, 26, 40],
          backgroundColor: "",
          height: 200,

          responsive: {
            rules: [{
              condition: {
                minWidth: 100,
                maxHeight: 150
              }
            }]
          }
        },

        navigator: {
          enabled: false
        },

        rangeSelector: {
          enabled: false
        },

        tooltip: {
          shared: false,
          formatter: function formatter() {
            var daPoint = this.point; //this.points[0].point;
            if (daPoint.open) {
              return (
                //   Highcharts.dateFormat("%e %B", this.x) +
                "<br />O:  " + daPoint.open.toFixed(2) + "<br />H:  " + daPoint.high.toFixed(2) + "<br />L:   " + daPoint.low.toFixed(2) + "<br />C: " + daPoint.close.toFixed(2)
              );
            } else {
              var entryPrice = open;
              return (
                //   Highcharts.dateFormat("%e %B", this.x) +
                "<br />Historical Average: " + ((daPoint.y - entryPrice) / entryPrice * 100).toFixed(2) + "%"
              );
            }
          }
        },

        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: "datetime",
          title: {
            text: null
          },
          gridLineWidth: 0,
          tickmarkPlacement: "on",
          dateTimeLabelFormats: {
            second: "%H:%M:%S",
            minute: "%H:%M",
            hour: "%H:%M",
            day: "%m/%d",
            week: "%m/%d",
            month: "%b",
            year: "%Y"
          },
          startOnTick: false,
          endOnTick: false,
          minPadding: 0.02
        },
        yAxis: [{
          title: {
            text: null
          },
          showFirstLabel: false,
          gridLineWidth: 0,
          startOnTick: false,
          endOnTick: false,
          tickPixelInterval: 20,
          min: minimumPrice,
          max: maximumPrice,
          plotLines: [{
            value: open, // entry price
            width: 1,
            color: "#262d44",
            dashStyle: "dash"
          }, {
            value: averageGainLossPrice, // average gain/loss value line
            width: 1,
            color: gainLossLineColor,
            dashStyle: "dash",
            label: {
              text: "<b>AVG G/L</b>",
              align: "right",
              y: 12,
              x: 0
            }
          }]
        }],

        legend: {
          enabled: true,
          align: "left",
          verticalAlign: "top",
          x: 43,
          y: -25,
          borderWidth: 0
        },

        plotOptions: {
          area: {
            fillOpacity: 0.25,
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            color: "rgba(33, 186, 69, 1.0)",
            fillColor: "rgba(33, 186, 69, 1.0)",
            negativeColor: "rgba(219, 40, 40, 1)",
            negativeFillColor: "rgba(219, 40, 40, 1)"
          }
        }
      };

      return chartOptions;
    }),

    chartData: Ember.computed("data", function () {
      var currentPerformance = this.get("currentPerformance");
      var historicalPerformance = this.get("historicalPerformance");

      var pointStart = _moment.default.utc(this.get("pattern.fromDate")).toDate();
      return [{
        type: "area",
        name: "Averaged Historical Data",
        pointStart: pointStart,
        data: historicalPerformance,
        color: "rgba(255, 0, 0, 0.5)",
        fillColor: "rgba(255, 0, 0, 0.3)",
        lineColor: "rgba(255, 0, 0, 0.4)",
        lineWidth: 1,
        dashStyle: "dash",
        shadow: false,
        pointInterval: 24 * 3600 * 1000,
        dataGrouping: [{
          units: [["day", [1]]]
        }]
      }, {
        type: "candlestick",
        name: "Current Security Data",
        data: currentPerformance,
        color: "rgba(255, 0, 0, 1.0)",
        shadow: false,
        pointInterval: 24 * 3600 * 1000,
        dataGrouping: [{
          units: [["day", [1]]]
        }],
        upColor: "rgba(0, 128, 0, 1.0)"
      }];
    })
  });
});
