define("seasonalysis/components/modals/delete-modal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      close: function close() {
        console.log("Closing modal");
        this.set("show", false);
        this.get("onCancel")();
      },
      cancel: function cancel() {
        console.log("Cancel delete!");
        this.set("show", false);
        this.get("onCancel")();
      },
      confirm: function confirm() {
        console.log("confirmDelete");
        var model = this.get("model");
        this.get("onConfirm")(model);
        this.set("show", false);
      }
    }
  });
});
