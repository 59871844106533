define("seasonalysis/components/forms/login-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    loading: false,
    loginError: null,

    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.$(".ui.form").form({
        fields: {
          username: ["empty"],
          password: ["empty"]
        }
      });
    },


    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set("loading", true);
        var credentials = this.getProperties("username", "password"),
            authenticator = "authenticator:token";
        this.get("session").authenticate(authenticator, credentials).then(function () {
          _this.set("loading", false);
        }, function (err) {
          _this.set("loading", false);
          _this.set("loginError", err.detail);
          _this.loginError = err.detail;
        });
      }
    }
  });
});
