define("seasonalysis/components/charts/yearly-averages-chart", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    chartOptions: Ember.computed(function () {
      var averageCharts = this.get("averageCharts");

      var series = [];
      averageCharts.forEach(function (chart) {
        var seriesData = chart.get("series");
        series.push(seriesData);
      });

      return {
        chart: {
          renderTo: "chart-container-1",
          zoomType: "",
          height: 350,
          margin: [30, 10, 20, 65]
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        xAxis: {
          type: "datetime",
          maxZoom: 14 * 24 * 3600000, // fourteen days
          title: {
            text: null
          },
          dateTimeLabelFormats: {
            second: "%H:%M:%S",
            minute: "%H:%M",
            hour: "%H:%M",
            day: "%e %b",
            week: "%e %b",
            month: "%b",
            year: "%Y"
          },
          tickmarkPlacement: "on",
          events: {
            setExtremes: function setExtremes() {
              //$("#reset-size-button").show();
            }
          },
          minPadding: 0.067,
          maxPadding: 0.067
        },
        yAxis: {
          title: {
            text: "Gain/Loss Percentage"
          },
          labels: {
            formatter: function formatter() {
              return this.value + "%";
            }
          },
          startOnTick: false,
          showFirstLabel: false
        },
        tooltip: {
          crosshairs: true,
          shared: false
        },
        legend: {
          enabled: true,
          floating: false,
          align: "center",
          layout: "horizontal",
          verticalAlign: "top",
          y: -10
        },
        plotOptions: {
          line: {
            animation: false,
            allowPointSelect: false,
            lineWidth: 2,
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: true,
                  radius: 3
                }
              }
            },
            shadow: false,
            states: {
              hover: {
                lineWidth: 2.5
              }
            }
          }
        },

        series: series
      };
    })
  });
});
