define("seasonalysis/components/modals/change-password", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    error: null,
    loading: false,

    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.$(".ui.form").form({
        fields: {
          currentPassword: ["empty"],
          newPassword: ["empty", "minLength[8]"],
          verifyPassword: ["empty", "match[newPassword]", "minLength[8]"]
        }
      });
    },


    actions: {
      close: function close() {
        this.set("show", false);
        this.set("loading", false);

        this.set("currentPassword", null);
        this.set("newPassword", null);
        this.set("verifyPassword", null);
        this.set("error", null);

        this.get("onClose")();
      },
      changePassword: function changePassword() {
        this.set("loading", true);
        var currentPassword = this.get("currentPassword");
        var newPassword = this.get("newPassword");

        var promise = this.get("onChangePassword")(currentPassword, newPassword);
        var self = this;

        promise.then(function (result) {
          self.set("loading", false);
          if (result == undefined) {
            self.send("close");
          } else {
            self.set("error", result.detail);
          }
        });
      }
    }
  });
});
