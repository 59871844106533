define("seasonalysis/models/current-pattern-detail", ["exports", "ember-data", "ember-data/relationships", "ember-data-model-fragments/attributes"], function (exports, _emberData, _relationships, _attributes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    pattern: (0, _relationships.belongsTo)("pattern", { async: false }),

    year: _emberData.default.attr("number"),
    entryDate: _emberData.default.attr("date"),
    entryPrice: _emberData.default.attr("number"),
    exitDate: _emberData.default.attr("date"),
    exitPrice: _emberData.default.attr("number"),
    gainLoss: _emberData.default.attr("number"),
    high: _emberData.default.attr("number"),
    low: _emberData.default.attr("number"),
    quotes: (0, _attributes.fragmentArray)("quote"),
    performance: _emberData.default.attr("array"),
    averages: _emberData.default.attr("array"),

    averageExitPrice: Ember.computed("pattern", function () {
      var pattern = this.get("pattern");
      console.log("computing average exit price", pattern);

      return 50;
    })
  });
});
