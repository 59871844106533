define("seasonalysis/models/pattern", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-model-fragments/attributes"], function (exports, _model, _attr, _relationships, _attributes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    security: (0, _relationships.belongsTo)("security", { inverse: null, async: false }),
    patternDetails: (0, _relationships.hasMany)("pattern-detail", { async: false }),
    currentPatternDetail: (0, _relationships.belongsTo)("current-pattern-detail", {
      async: false
    }),

    favorited: (0, _attr.default)("boolean"),

    date: (0, _attr.default)("date"),
    year: (0, _attr.default)("number"),
    length: (0, _attr.default)("number"),
    yearsOfData: (0, _attr.default)("number"),
    winPercentage: (0, _attr.default)("number"),
    fromDate: (0, _attr.default)("date"),
    toDate: (0, _attr.default)("date"),
    averageGainLoss: (0, _attr.default)("number"),
    maxGain: (0, _attr.default)("number"),
    maxLoss: (0, _attr.default)("number"),
    averageHigh: (0, _attr.default)("number"),
    averageLow: (0, _attr.default)("number"),
    standardDeviation: (0, _attr.default)("number"),
    up: (0, _attr.default)("number"),
    down: (0, _attr.default)("number"),
    patternRating: (0, _attr.default)("number"),
    patternPerformance: (0, _attributes.fragmentArray)("pattern-performance"),

    sparkData: Ember.computed(function () {
      var performance = this.get("patternPerformance");
      var years = performance.map(function (a) {
        return a.get("year");
      });
      var gainLosses = performance.map(function (a) {
        return a.get("gainLoss");
      });
      var data = [];
      for (var idx in gainLosses) {
        var color = function () {
          if (gainLosses[idx] >= 0) {
            return "rgba(33, 186, 69, 1.0)";
          }

          return "rgba(219, 40, 40, 1.0)";
        }();

        if (idx < gainLosses.length) {
          // Why do we need this?
          data.push({ name: years[idx], color: color, y: gainLosses[idx] });
        }
      }

      var result = [{ data: data }];
      return result;
    }),

    bestGL: Ember.computed("maxGain", "maxLoss", function () {
      return {
        bestGain: this.get("maxGain"),
        worstLoss: this.get("maxLoss")
      };
    })
  });
});
