define("seasonalysis/components/patterns-table", ["exports", "ember-light-table"], function (exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),

    isLoading: Ember.computed.oneWay("fetchRecords.isRunning"),
    canLoadMore: true,
    enableSync: true,

    init: function init() {
      this._super.apply(this, arguments);

      var table = new _emberLightTable.default(this.get("columns"), this.get("model.patterns"), {
        enableSync: this.get("enableSync")
      });
      var sortColumn = table.get("allColumns").findBy("valuePath", this.get("sort"));

      // Setup initial sort column
      if (sortColumn) {
        sortColumn.set("sorted", true);
      }

      this.set("table", table);
    },


    observeShouldReload: Ember.observer("model.meta", function () {}),

    actions: {
      onScrolledToBottom: function onScrolledToBottom() {
        // if (this.get("canLoadMore")) {
        //   this.incrementProperty("page");
        //   let loadRecords = this.get("loadRecords");
        //   loadRecords();
        // }
      },
      onPageChanged: function onPageChanged(newPage) {
        this.get("pageChanged")(newPage);
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? "asc" : "desc",
            sort: column.get("valuePath"),
            canLoadMore: true,
            page: 0
          });

          var sortChanged = this.get("sortChanged");
          sortChanged({
            order: column.ascending ? "asc" : "desc",
            field: column.get("valuePath")
          });
        }
      },
      patternClicked: function patternClicked(row) {
        if (this.checkboxClicked === true) {
          this.checkboxClicked = false;
          return;
        }
        this.get("router").transitionTo("pattern-detail", row.content.id);
      },
      checkboxClicked: function checkboxClicked(row) {
        this.checkboxClicked = true;
        var favorited = row.content.get("favorited");
        row.content.set("favorited", !favorited);
        row.content.save();
      },
      securityClicked: function securityClicked(row) {
        this.get("router").transitionTo("security-detail", row.content.get("security.id"));
      }
    },

    columns: Ember.computed(function () {
      return [{
        label: "",
        valuePath: "favorited",
        width: "30px",
        sortable: false,
        cellComponent: "cells/pattern-actions-cell",
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "Date",
        valuePath: "fromDate",
        width: "71px",
        sortable: true,
        cellComponent: "cells/pattern-date-cell"
      }, {
        label: "Security",
        valuePath: "security",
        width: "90px",
        sortable: false,
        cellComponent: "cells/security-cell"
      }, {
        label: "Win %",
        valuePath: "winPercentage",
        width: "80px",
        format: function format(value) {
          return value.toFixed(1) + "%";
        }
      }, {
        label: "Avg G/L",
        valuePath: "averageGainLoss",
        cellComponent: "cells/gain-loss-cell",
        width: "90px"
      }, {
        label: "Avg Hi",
        valuePath: "averageHigh",
        sortable: false,
        cellComponent: "cells/gain-loss-cell",
        width: "70px",
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "Avg Lo",
        valuePath: "averageLow",
        sortable: false,
        cellComponent: "cells/gain-loss-cell",
        width: "70px",
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "Std Dev",
        valuePath: "standardDeviation",
        cellComponent: "cells/number-cell",
        width: "89px",
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "Length",
        valuePath: "length",
        width: "83px",
        format: function format(value) {
          return value + " days";
        },
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "History",
        valuePath: "yearsOfData",
        width: "87px",
        format: function format(value) {
          return value + " years";
        },
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "Rating",
        valuePath: "patternRating",
        width: "82px",
        format: function format(value) {
          return value / 10;
        },
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "Best G/L",
        width: "120px",
        valuePath: "bestGL",
        sortable: false,
        cellComponent: "cells/best-gain-loss-cell",
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "History",
        valuePath: "sparkData",
        cellComponent: "cells/spark-bar-cell",
        sortable: false,
        breakpoints: ["tablet", "desktop", "jumbo"]
      }];
    })
  });
});
