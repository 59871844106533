define("seasonalysis/components/security-search", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    value: "",
    loading: false,

    actions: {
      handleSecurityLookup: function handleSecurityLookup() {
        var _this = this;

        var filterInputValue = this.get("value");
        this.set("loading", true);

        return Ember.run.debounce(this, function () {
          return _this.get("store").query("security", { search: filterInputValue }).then(function (results) {
            _this.set("loading", false);
            return _this.set("results", results);
          });
        }, 500);
      },
      securityClicked: function securityClicked(security) {
        console.log("securityClicked", security.id);
        this.set("results", null);
        this.set("value", "");
        this.get("router").transitionTo("security-detail", security.id);
      },
      clearResults: function clearResults() {
        this.set("results", null);
        this.set("value", "");
      }
    }
  });
});
