define("seasonalysis/models/security", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    securityType: _emberData.default.attr("string"),
    stock: (0, _attributes.fragment)("stock"),
    securityDetail: Ember.computed("id", function () {
      var id = this.get("id");
      return this.store.peekRecord("security-detail", id + "-details");
    }),
    securityCharts: Ember.computed("id", function () {
      var id = this.get("id");
      var securityChartsID = id + "-charts";
      console.log("finding secutity charts", securityChartsID);
      return this.store.peekRecord("security-chart", securityChartsID);
    })
  });
});
