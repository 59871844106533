define("seasonalysis/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "seasonalysis/config/environment"], function (exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    namespace: _environment.default.API_NAMESPACE,
    host: _environment.default.API_HOST,
    authorizer: "authorizer:application",

    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 400 && payload.errors) {
        return new _emberData.default.InvalidError(payload.errors);
      } else if (status === 400 && payload.error) {
        return new _emberData.default.InvalidError([payload.error]);
      }

      return this._super.apply(this, arguments);
    },

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      console.log("requestType", requestType, query);
      var result = this._super(modelName, id, snapshot, requestType, query);
      console.log("buildURL.result", modelName, id, snapshot, requestType, query);
      var includes = function () {
        if (query != undefined && query.hasOwnProperty("include")) {
          return query.include;
        }

        if (snapshot != undefined && snapshot.hasOwnProperty("include")) {
          return snapshot.include;
        }

        return [];
      }();

      if (includes != undefined && includes.length > 0) {
        result = result + "?";

        result = result + "include[]=" + includes.join(",");

        if (snapshot != undefined && snapshot.hasOwnProperty("include")) {
          delete snapshot["include"];
        } else if (query != undefined && query.hasOwnProperty("include")) {
          delete query["include"];
        }
      }

      var pagination = function () {
        if (query != undefined && query.hasOwnProperty("pagination")) {
          return query.pagination;
        }

        if (snapshot != undefined && snapshot.hasOwnProperty("pagination")) {
          return snapshot.pagination;
        }

        return null;
      }();

      if (pagination != null) {
        console.log("found pagination", pagination);
        result = result + "&page[size]=" + pagination.pageSize + "&page[number]=" + pagination.pageNumber;
      }

      return result;
    }
  });
});
