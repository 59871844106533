define("seasonalysis/authenticators/token", ["exports", "ember-simple-auth-token/authenticators/jwt"], function (exports, _jwt) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jwt.default.extend({
    session: Ember.inject.service(),
    // serverTokenEndpoint: ENV.HOST + ENV.NAMESPACE + "/auth/token",
    // serverTokenRefreshEndpoint: ENV.HOST + ENV.NAMESPACE + "/auth/refresh",

    restore: function restore(data) {
      var result = this._super(data);
      return result;
    },
    authenticate: function authenticate(credentials) {
      var headers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var username = credentials.username;
      var password = credentials.password;

      var base64String = btoa(username + ":" + password);
      var authString = "Basic " + base64String;

      headers = {
        Authorization: authString
      };

      return this._super(credentials, headers);
    },
    handleAuthResponse: function handleAuthResponse(response) {
      var token = Ember.get(response, this.tokenPropertyName);
      var assign = assign || Ember.merge;

      if (Ember.isEmpty(token)) {
        throw new Error("Token is empty. Please check your backend response.");
      }

      var expiresAt = Ember.get(response, this.tokenExpireName);
      var tokenExpireData = {};

      tokenExpireData[this.tokenExpireName] = expiresAt;

      if (this.refreshAccessTokens) {
        var refreshToken = Ember.get(response, this.refreshTokenPropertyName);

        if (Ember.isEmpty(refreshToken)) {
          throw new Error("Refresh token is empty. Please check your backend response.");
        }

        this.scheduleAccessTokenRefresh(expiresAt, refreshToken);
      }

      return assign(this.getResponseData(response), tokenExpireData);
    },
    getTokenData: function getTokenData(token) {
      return token;
    },
    invalidate: function invalidate(data) {
      return this._super(data);
    }
  });
});
