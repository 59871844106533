define("seasonalysis/initializers/fragment-serializer", ["exports", "seasonalysis/serializers/fragment"], function (exports, _fragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.register("serializer:-fragment", _fragment.default);
  }

  exports.default = {
    name: "fragment-serializer",
    initialize: initialize
  };
});
