define("seasonalysis/routes/security-baskets/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "seasonalysis/mixins/subscription"], function (exports, _authenticatedRouteMixin, _subscription) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _subscription.default, {
    title: "Seasonalysis | Security Baskets",
    model: function model(params) {
      return this.store.query("security-basket", {
        include: ["securities", "security"],
        page: {
          number: 1,
          size: 25
        }
      });
    },


    actions: {
      openModal: function openModal(name) {
        $(".ui." + name + ".modal").modal("show");
      },

      addBasket: function addBasket(name) {
        var _this = this;

        console.log("addBasket:", name);
        var newBasket = this.get("store").createRecord("security-basket", {
          name: name
        });

        newBasket.save().then(function (basket) {
          _this.transitionTo("security-baskets.show", basket.id);
        }).catch(function (error) {
          console.log("somethign went wrong", error);
          return error;
        });
      },
      cancelAddBasket: function cancelAddBasket() {
        console.log("cancelAddBasket");
      }
    }
  });
});
