define("seasonalysis/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "seasonalysis/mixins/subscription"], function (exports, _applicationRouteMixin, _subscription) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),

    model: function model() {
      var userID = this.get("session.data.authenticated.user_id");

      return Ember.RSVP.hash({
        // user: this.get("store")
        //   .findRecord("user", userID, {
        //     include: ["user_preferences"]
        //   })
        //   .then(function(stuff) {
        //     // Do something with `peters`
        //     let username = stuff.get("userPreferences.firstName");
        //     console.log(`Currently logged in as ${username}`);
        //     console.log("stuff", stuff);
        //     return stuff;
        //   })
      });
    },
    afterModel: function afterModel(model, transition) {
      // if (model.user.get("status") == "no_subscription") {
      //   console.log("no subscription :(");
      //   this.transitionTo("settings");
      // }
    },


    title: function title(tokens) {
      tokens = Ember.makeArray(tokens);
      return "Seasonalysis - " + tokens.join(" - ") + " - ";
    }
  });
});
