define("seasonalysis/routes/forgot-password/index", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    title: "Seasonalysis | Forgot Password"
  });
});
