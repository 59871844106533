define("seasonalysis/models/fragments/seasonality-chart", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "moment"], function (exports, _fragment, _attr, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    daySet: (0, _attr.default)("number"),
    startDate: (0, _attr.default)("date"),
    longPatterns: (0, _attr.default)("array"),
    shortPatterns: (0, _attr.default)("array"),

    series: Ember.computed("startDate", "name", "chartData", function () {
      var startDate = this.get("startDate");
      var longPatterns = this.get("longPatterns");
      var shortPatterns = this.get("shortPatterns");

      var longData = longPatterns.map(function (value) {
        return { y: value, color: "rgba(0, 128, 0, 0.25)" };
      });

      var shortData = shortPatterns.map(function (value) {
        return { y: value, color: "rgba(255, 0, 0, 0.25)" };
      });

      var longSeries = {
        type: "column",
        yAxis: 1,
        pointWidth: 2,
        pointPadding: 1,
        name: "Long Patterns",
        pointInterval: 24 * 3600 * 1000,
        pointStart: _moment.default.utc(startDate).valueOf(),
        data: longData
      };

      var shortSeries = {
        type: "column",
        yAxis: 1,
        pointWidth: 2,
        pointPadding: 1,
        name: "Short Patterns",
        pointInterval: 24 * 3600 * 1000,
        pointStart: _moment.default.utc(startDate).valueOf(),
        data: shortData
      };

      return [longSeries, shortSeries];
    })
  });
});
