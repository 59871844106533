define("seasonalysis/components/pattern-current-performance", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    patternProgress: Ember.computed("pattern.currentPatternDetail", function () {
      var fromDate = _moment.default.utc(this.pattern.get("fromDate"));
      var toDate = _moment.default.utc(this.pattern.get("toDate"));
      var dayCount = toDate.diff(fromDate, "days") + 1;
      var lastQuote = this.pattern.get("currentPatternDetail.quotes.lastObject");
      var lastQuoteDate = _moment.default.utc(lastQuote.get("date"));

      var currentDay = lastQuoteDate.diff(fromDate, "days") + 1;
      var progress = (currentDay / dayCount * 100).toFixed(1);

      return currentDay + " / " + dayCount + " Days (" + progress + "% Complete)";
    }),

    entryPrice: Ember.computed("pattern.currentPatternDetail", function () {
      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var first = quotes.get("firstObject");
      return "$" + first.get("open");
    }),

    exitPrice: Ember.computed("pattern.currentPatternDetail", function () {
      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var last = quotes.get("lastObject");
      var date = last.get("date");
      // TODO: Handle N/A if pattern isn't complete
      return "$" + last.get("close");
    }),

    gainLossAmount: Ember.computed("pattern.currentPatternDetail", function () {
      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var entry = quotes.get("firstObject");
      var exit = quotes.get("lastObject");
      // TODO: Handle N/A if pattern isn't complete

      var amount = exit.get("close") - entry.get("open");
      return amount;
    }),

    interimHighPrice: Ember.computed("pattern.currentPatternDetail", function () {
      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var entry = quotes.get("firstObject");
      var maxHigh = entry.get("high");

      quotes.forEach(function (quote) {
        var high = quote.get("high");
        if (high > maxHigh) {
          maxHigh = high;
        }
      });

      return maxHigh;
    }),

    interimHighPercent: Ember.computed("pattern.currentPatternDetail", function () {
      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var entry = quotes.get("firstObject");
      var entryPrice = entry.get("open");
      var maxHigh = entry.get("high");

      quotes.forEach(function (quote) {
        var high = quote.get("high");
        if (high > maxHigh) {
          maxHigh = high;
        }
      });

      var interimHighPercent = (maxHigh / entryPrice - 1) * 100;

      return interimHighPercent;
    }),

    interimLowPrice: Ember.computed("pattern.currentPatternDetail", function () {
      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var entry = quotes.get("firstObject");
      var maxLow = entry.get("low");

      quotes.forEach(function (quote) {
        var low = quote.get("low");
        if (low < maxLow) {
          maxLow = low;
        }
      });

      return maxLow;
    }),

    interimLowPercent: Ember.computed("pattern.currentPatternDetail", function () {
      var quotes = this.pattern.get("currentPatternDetail.quotes");
      var entry = quotes.get("firstObject");
      var entryPrice = entry.get("open");
      var maxLow = entry.get("low");

      quotes.forEach(function (quote) {
        var low = quote.get("low");
        if (low < maxLow) {
          maxLow = low;
        }
      });

      var interimLowPercent = (maxLow / entryPrice - 1) * 100;

      return interimLowPercent;
    })
  });
});
