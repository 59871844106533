define("seasonalysis/routes/security-baskets/show", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "seasonalysis/mixins/subscription"], function (exports, _authenticatedRouteMixin, _subscription) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _actions;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _subscription.default, {
    deleting: true,
    showAddSecurityModal: false,
    shouldClearSecurityModalText: true,
    showDeleteSecurityBasketModal: false,
    result: null,

    titleToken: function titleToken(model) {
      return "Security Baskets | " + model.get("name");
    },

    model: function model(params) {
      return this.get("store").findRecord("security-basket", params.id, {
        include: ["securities"]
      });
    },


    /*
    Issues:
    - Buttons always seem to close the modal
    - Hiding the modal works but you get n+1 if you reload the route, not sure why
    */
    actions: (_actions = {
      openModal: function openModal(name) {
        $(".ui." + name + ".modal").modal("show");
      },

      handleSecurityLookup: function handleSecurityLookup() {
        var _this = this;

        var filterInputValue = this.get("value");
        this.set("loading", true);

        return Ember.run.debounce(this, function () {
          return _this.get("store").query("security", { search: filterInputValue }).then(function (results) {
            _this.set("loading", false);
            return _this.set("results", results);
          });
        }, 500);
      },
      closeAddSecurityModal: function closeAddSecurityModal() {
        console.log("closing..");
        this.set("showAddSecurityModal", false);
      },
      addSecurity: function addSecurity(security) {
        console.log("addSecurity..");
        var basket = this.controller.get("model");
        basket.get("securities").addObject(security);
        basket.save();
      },
      removeSecurity: function removeSecurity(security) {
        console.log("removeSecurity..", security);

        var basket = this.controller.get("model");
        basket.get("securities").removeObject(security);
        basket.save();
      }
    }, _defineProperty(_actions, "closeAddSecurityModal", function closeAddSecurityModal() {
      console.log("closing..");
      this.set("showAddSecurityModal", false);
      this.set("shouldClearSecurityModalText", true);
    }), _defineProperty(_actions, "deleteBasket", function deleteBasket(basket) {
      var _this2 = this;

      console.log("deleting basket!", basket.id);
      this.set("showDeleteSecurityBasketModal", false);
      this.get("store").findRecord("security-basket", basket.id, { reload: true }).then(function (basket) {
        return basket.destroyRecord();
      }).then(function () {
        _this2.transitionTo("security-baskets");
      });
    }), _defineProperty(_actions, "cancelDeleteBasket", function cancelDeleteBasket() {
      console.log("cancel deleting basket!");
      this.set("showDeleteSecurityBasketModal", false);
    }), _actions)
  });
});
