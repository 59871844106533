define("seasonalysis/adapters/security-basket", ["exports", "seasonalysis/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(type) {
      return "baskets";
    }
  });
});
