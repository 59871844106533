define("seasonalysis/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    afterModel: function afterModel() {
      //this.showLoginModalEnsuringState({onCloseDestinationRoute: '/'});
    }
  });
});
