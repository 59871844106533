define("seasonalysis/mixins/subscription", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var self = this;
      console.log("subscription mixin called beforeModel");
      if (this.get("session.isAuthenticated")) {
        var userID = this.get("session.data.authenticated.user_id");

        this.get("store").findRecord("user", userID, {
          include: ["user_preferences"]
        }).then(function (user) {
          // Do something with `peters`
          console.log("Currently logged in as " + user.get("status"));
          if (user.get("status") == "no_subscription") {
            console.log("no subscription :(");
            //self.transitionTo("settings");
          }
        });
      }
      return this._super.apply(this, arguments);
    }
  });
});
