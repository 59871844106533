define("seasonalysis/models/user", ["exports", "ember-data", "ember-api-actions"], function (exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    emailAddress: _emberData.default.attr("string"),
    username: _emberData.default.attr("string"),
    status: _emberData.default.attr("string"),

    changePassword: (0, _emberApiActions.memberAction)({ path: "change-password", type: "post" })
  });
});
