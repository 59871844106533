define("seasonalysis/routes/settings", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    title: "Seasonalysis | Settings",
    color: "#262D44",
    showChangePasswordModal: false,

    model: function model() {
      var userID = this.get("session.data.authenticated.user_id");

      return Ember.RSVP.hash({
        user: this.get("store").findRecord("user", userID, {
          include: ["user_preferences"]
        }).then(function (stuff) {
          // Do something with `peters`
          var username = stuff.get("userPreferences.firstName");
          console.log("Currently logged in as " + username);
          console.log("stuff", stuff);
          return stuff;
        })
      });
    },


    actions: {
      closeChangePasswordModal: function closeChangePasswordModal() {
        this.set("showChangePasswordModal", false);
      },
      changePassword: function changePassword(oldPassword, newPassword) {
        var user = this.controller.get("model").user;

        return user.changePassword({
          current_password: oldPassword,
          new_password: newPassword
        }).then(function (response) {
          return;
        }).catch(function (adapterError) {
          return adapterError.errors[0];
        });
      },
      updateProfile: function updateProfile(changeset) {
        return changeset.save();
      },
      rollbackProfile: function rollbackProfile(changeset) {
        return changeset.rollback();
      },
      validate: function validate(_ref) {
        var key = _ref.key,
            newValue = _ref.newValue,
            oldValue = _ref.oldValue,
            changes = _ref.changes,
            content = _ref.content;

        console.log("validating..");
        // lookup a validator function on your favorite validation library
        // should return a Boolean
      },
      processStripeToken: function processStripeToken(stuff) {}
    }
  });
});
