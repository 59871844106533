define("seasonalysis/models/security-detail", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    security: _emberData.default.belongsTo("security", { inverse: null }),
    patternCount: (0, _attributes.fragmentArray)("pattern-count"),
    monthlySummary: (0, _attributes.fragmentArray)("monthly-summary"),
    yearlyStats: (0, _attributes.fragmentArray)("yearly-stats"),

    yearlyStatsColumns: Ember.computed(function () {
      var months = ["Year", "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC", "Y/E"];
      return months;
    })
  });
});
