define("seasonalysis/components/forms/profile-form", ["exports", "ember-changeset", "ember-changeset-validations", "seasonalysis/validations/user"], function (exports, _emberChangeset, _emberChangesetValidations, _user) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    UserValidations: _user.default,

    init: function init() {
      this._super.apply(this, arguments);
      var model = get(this, "model");
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_user.default), _user.default);
    },


    actions: {
      rollback: function rollback(changeset) {
        this.get("onRollback")(changeset);
      },
      submit: function submit(changeset) {
        var self = this;

        return changeset.validate().then(function () {
          var isValid = changeset.get("isValid");
          if (!isValid) {
            return;
          }

          self.get("onSubmit")(changeset);
        });
      },
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      }
    }
  });
});
