define("seasonalysis/components/forms/signup-form", ["exports", "seasonalysis/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    signUpError: null,
    loading: false,
    signUpComplete: false,

    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.$(".ui.form").form({
        fields: {
          firstname: "empty",
          lastname: "empty",
          username: ["empty"],
          password: ["minLength[6]"],
          email: ["email"],
          terms: ["checked"]
        }
      });
    },

    actions: {
      save: function save() {
        var _this = this;

        this.set("loading", true);
        Ember.$(".ui.form").form({
          fields: {
            firstname: "empty",
            lastname: "empty",
            username: ["empty"],
            password: ["minLength[6]"],
            email: ["email"]
          }
        });

        if (!Ember.$(".ui.form").form("is valid")) {
          return;
        }

        var credentials = this.getProperties("firstName", "lastName", "username", "password", "email");

        var data = {
          first_name: credentials.firstName,
          last_name: credentials.lastName,
          email_address: credentials.email,
          username: credentials.username,
          password: credentials.password
        };

        Ember.$.ajax({
          type: "POST",
          url: _environment.default.API_HOST + "/v1/public/auth/register",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data)
        }).done(function () {
          _this.set("signUpComplete", true);
        }).fail(function (response) {
          var errorData = response.responseJSON["error"];

          _this.set("signUpError", errorData.detail);
        }).always(function () {
          _this.set("loading", false);
        });
      }
    }
  });
});
