define("seasonalysis/routes/signup/complete", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    title: "Seasonalysis | Complete Registration",

    model: function model(params) {
      console.log("params", params);
      return {
        confirmationCode: params.id
      };
    }
  });
});
