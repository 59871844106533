define("seasonalysis/components/forms/signup-complete", ["exports", "seasonalysis/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    loading: true,
    confirmationCode: null,
    confirmationError: null,
    confirmationSuccessful: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.send("confirmRegistration");
      console.log("didReceiveAttrs");
    },


    actions: {
      confirmRegistration: function confirmRegistration() {
        var _this = this;

        console.log("confirm registration", this.get("code"));

        var data = {
          code: this.get("code")
        };

        Ember.$.ajax({
          type: "GET",
          url: _environment.default.API_HOST + "/v1/public/auth/register/complete",
          contentType: "application/json",
          dataType: "json",
          data: data
        }).done(function () {
          _this.set("loading", false);
          _this.set("confirmationSuccessful", true);
        }).fail(function (response) {
          _this.set("loading", false);
          var errorData = response.responseJSON["error"];

          _this.set("confirmationError", errorData.detail);
          _this.confirmationError = errorData.detail;
        });
      }
    }
  });
});
