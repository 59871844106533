define("seasonalysis/controllers/my-patterns", ["exports", "ember-parachute"], function (exports, _emberParachute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.myQueryParams = undefined;
  var myQueryParams = exports.myQueryParams = new _emberParachute.default({
    view: {
      as: "view",
      defaultValue: "all",
      refresh: true,
      replace: true
    },
    pageSize: {
      as: "size",
      defaultValue: 50,
      refresh: true,
      replace: true
    },
    pageNumber: {
      as: "page",
      defaultValue: 1,
      refresh: true,
      replace: true
    }
  });

  exports.default = Ember.Controller.extend(myQueryParams.Mixin, {
    queryParamsChanged: Ember.computed.or("queryParamsState.{view}.changed"),

    setup: function setup(_ref) {
      var queryParams = _ref.queryParams;

      this.fetchData(queryParams);
    },
    queryParamsDidChange: function queryParamsDidChange(_ref2) {
      var shouldRefresh = _ref2.shouldRefresh,
          queryParams = _ref2.queryParams;

      if (shouldRefresh) {
        this.send("reload");
      }
    },
    reset: function reset(_ref3, isExiting) {
      var queryParams = _ref3.queryParams;

      if (isExiting) {
        this.resetQueryParams();
      }
    },
    fetchData: function fetchData(queryParams) {
      // fetch data
    },


    actions: {
      resetAll: function resetAll() {
        // reset all query params to their default values specified in the query param map
        this.resetQueryParams();
      }
    }
  });
});
