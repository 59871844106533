define("seasonalysis/router", ["exports", "seasonalysis/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("login");
    this.route("secret");
    this.route("settings");
    this.route("discover");
    this.route("my-patterns");
    this.route("email-alerts");
    this.route("security-baskets", function () {
      this.route("show", { path: "/:id" });
    });
    this.route("pattern-detail", { path: "/patterns/:id" }, function () {
      // this.route('comment', { path: '/comment/:id' });
    });
    this.route("security-detail", { path: "/securities/:id" }, function () {
      // this.route('comment', { path: '/comment/:id' });
    });
    this.route("signup", function () {
      this.route("complete", { path: "/complete/:id" });
    });
    this.route("forgot-password", function () {
      this.route("reset", { path: "/reset/:id" });
    });
    this.route("terms");
    this.route("404", { path: "/*path" });
  });

  exports.default = Router;
});
