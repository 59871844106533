define("seasonalysis/components/charts/pattern-performance", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    chartOptions: Ember.computed("pattern", function () {
      return {
        chart: {
          // renderTo: (options.chart && options.chart.renderTo) || this,
          backgroundColor: null,
          borderWidth: 0,
          type: "area",
          margin: [2, 0, 2, 0],
          width: 280,
          height: 20,
          style: {
            overflow: "visible"
          },

          // small optimalization, saves 1-2 ms each sparkline
          skipClone: true
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [],
          lineWidth: 0,
          tickWidth: 0
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
          // backgroundColor: null,
          // borderWidth: 0,
          // shadow: false,
          // useHTML: true,
          // hideDelay: 0,
          // shared: true,
          // padding: 0,
          // positioner: function(w, h, point) {
          //   return { x: point.plotX - w / 2, y: point.plotY - h };
          // }
        },
        plotOptions: {
          area: {
            fillOpacity: 0.25,
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            color: "rgba(33, 186, 69, 1.0)",
            fillColor: "rgba(33, 186, 69, 1.0)",
            negativeColor: "rgba(219, 40, 40, 1)",
            negativeFillColor: "rgba(219, 40, 40, 1)"
          }
        }
      };
    }),

    chartData: Ember.computed("data", function () {
      var detail = this.get("detail");
      return [{
        name: detail.get("year"),
        data: detail.get("performance")
      }];
    })
  });
});
