define('seasonalysis/components/pattern-filter', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showSearchRangeModal: false,
    showpatternDurationModal: false,
    showGainLossRangeModal: false,
    showWinPercentageModal: false,
    showPatternHistoryModal: false,
    showExchangesModal: false,
    showStandardDeviationModal: false,
    showMarketCapModal: false,
    showAverageDailyVolumeModal: false,
    showSecurityPriceModal: false,

    winPercentageLabel: '80% to 100%',
    marketCapLabel: 'Any',
    standardDeviationLabel: 'Any',
    averageDailyVolumeLabel: 'Any',
    securityPriceLabel: 'Any',

    observeStandardDeviation: Ember.observer('screener.filter.standard_deviation', function () {
      var value = this.get('screener.filter.standard_deviation');
      var found = this.defaults.standardDeviation.find(function (element) {
        return element.value == value;
      });

      this.set('standardDeviationLabel', found.label);
    }),
    observeMarketCap: Ember.observer('screener.filter.marketCap.gte', 'screener.filter.marketCap.lte', function () {
      this.updateMarketCapLabel();
    }),

    updateMarketCapLabel: function updateMarketCapLabel() {
      // Find values
      var lteValue = this.get('screener.filter.marketCap.lte');
      var gteValue = this.get('screener.filter.marketCap.gte');

      var lte = this.defaults.marketCap.find(function (element) {
        return element.value == lteValue;
      });

      var gte = this.defaults.marketCap.find(function (element) {
        return element.value == gteValue;
      });

      var isNull = function isNull(value) {
        if (value == null) {
          return true;
        }

        if (value == 'all') {
          return true;
        }

        return false;
      };

      if (lte == undefined && gte == undefined) {
        // lte empty, gte empty
        this.set('marketCapLabel', 'Any');
      } else if (lte != undefined && gte == undefined) {
        // lte not empty, gte empty
        if (isNull(lte.value)) {
          this.set('marketCapLabel', 'Any');
        } else {
          this.set('marketCapLabel', 'Up to ' + lte.label);
        }
      } else if (lte == undefined && gte != undefined) {
        // gte not empty, lte empty
        this.set('marketCapLabel', gte.label + ' and up');
      } else if (lte != undefined && gte != undefined) {
        // gte not empty, lte not empty
        if (isNull(gte.value) && isNull(lte.value)) {
          this.set('marketCapLabel', 'Any');
        } else if (!isNull(gte.value) && isNull(lte.value)) {
          this.set('marketCapLabel', gte.label + ' and up');
        } else if (isNull(gte.value) && !isNull(lte.value)) {
          this.set('marketCapLabel', 'Up to ' + lte.label);
        } else if (!isNull(gte.value) && !isNull(lte.value)) {
          this.set('marketCapLabel', gte.label + ' to ' + lte.label);
        }
      }
    },


    observeAverageDailyVolumeLTE: Ember.observer('screener.filter.average_volume.lte', 'screener.filter.average_volume.gte', function () {
      this.updateAverageDailyVolumeLabel();
    }),
    updateAverageDailyVolumeLabel: function updateAverageDailyVolumeLabel() {
      // Find values
      var lteValue = this.get('screener.filter.average_volume.lte');
      var gteValue = this.get('screener.filter.average_volume.gte');

      var isNull = function isNull(value) {
        if (value == null) {
          return true;
        }

        if (value == 0) {
          return true;
        }

        return false;
      };

      var lte = this.defaults.dailyVolume.find(function (element) {
        return element.value == lteValue;
      });

      var gte = this.defaults.dailyVolume.find(function (element) {
        return element.value == gteValue;
      });

      if (lte == undefined && gte == undefined) {
        // lte empty, gte empty
        this.set('averageDailyVolumeLabel', 'Any');
      } else if (lte != undefined && gte == undefined) {
        // lte not empty, gte empty
        if (isNull(lte.value)) {
          this.set('averageDailyVolumeLabel', 'Any');
        } else {
          this.set('averageDailyVolumeLabel', 'Up to ' + lte.label);
        }
      } else if (lte == undefined && gte != undefined) {
        // gte not empty, lte empty
        this.set('averageDailyVolumeLabel', gte.label + ' and up');
      } else if (lte != undefined && gte != undefined) {
        // gte not empty, lte not empty
        if (isNull(gte.value) && isNull(lte.value)) {
          this.set('averageDailyVolumeLabel', 'Any');
        } else if (!isNull(gte.value) && isNull(lte.value)) {
          this.set('averageDailyVolumeLabel', gte.label + ' and up');
        } else if (isNull(gte.value) && !isNull(lte.value)) {
          this.set('averageDailyVolumeLabel', 'Up to ' + lte.label);
        } else if (!isNull(gte.value) && !isNull(lte.value)) {
          this.set('averageDailyVolumeLabel', gte.label + ' to ' + lte.label);
        }
      }
    },


    observeSecurityPriceLTE: Ember.observer('screener.filter.security_price.lte', 'screener.filter.security_price.gte', function () {
      this.updateSecurityPriceLabel();
    }),

    updateSecurityPriceLabel: function updateSecurityPriceLabel() {
      // Find values
      var lteValue = this.get('screener.filter.security_price.lte');
      var gteValue = this.get('screener.filter.security_price.gte');

      var isNull = function isNull(value) {
        if (value == null) {
          return true;
        }

        if (value == 0) {
          return true;
        }

        return false;
      };

      var lte = this.defaults.securityPrice.find(function (element) {
        return element.value == lteValue;
      });

      var gte = this.defaults.securityPrice.find(function (element) {
        return element.value == gteValue;
      });

      if (lte == undefined && gte == undefined) {
        // lte empty, gte empty
        this.set('securityPriceLabel', 'Any');
      } else if (lte != undefined && gte == undefined) {
        // lte not empty, gte empty
        if (isNull(lte.value)) {
          this.set('securityPriceLabel', 'Any');
        } else {
          this.set('securityPriceLabel', 'Up to ' + lte.label);
        }
      } else if (lte == undefined && gte != undefined) {
        // gte not empty, lte empty
        this.set('securityPriceLabel', gte.label + ' and up');
      } else if (lte != undefined && gte != undefined) {
        // gte not empty, lte not empty
        if (isNull(gte.value) && isNull(lte.value)) {
          this.set('securityPriceLabel', 'Any');
        } else if (!isNull(gte.value) && isNull(lte.value)) {
          this.set('securityPriceLabel', gte.label + ' and up');
        } else if (isNull(gte.value) && !isNull(lte.value)) {
          this.set('securityPriceLabel', 'Up to ' + lte.label);
        } else if (!isNull(gte.value) && !isNull(lte.value)) {
          this.set('securityPriceLabel', gte.label + ' to ' + lte.label);
        }
      }
    },


    dateRangeOptions: [{
      action: 'selectDateRange',
      label: 'Today',
      actionValue: [(0, _moment.default)().startOf('day')],
      classes: 'ui blue button date-picker'
    }, {
      action: 'selectDateRange',
      label: 'Next Week',
      actionValue: [(0, _moment.default)().startOf('day'), (0, _moment.default)().startOf('day').add(6, 'day')],
      classes: 'ui blue button date-picker'
    }, {
      action: 'selectDateRange',
      label: 'Next Month',
      actionValue: [(0, _moment.default)().startOf('day'), (0, _moment.default)().startOf('day').add(30, 'day')],
      classes: 'ui blue button date-picker'
    }, {
      action: 'selectDateRange',
      label: 'Previous Week',
      actionValue: [(0, _moment.default)().startOf('day').subtract(6, 'day'), (0, _moment.default)().startOf('day')],
      classes: 'ui blue button date-picker'
    }, {
      action: 'selectDateRange',
      label: 'Previous Month',
      actionValue: [(0, _moment.default)().startOf('day').subtract(30, 'day'), (0, _moment.default)().startOf('day')],
      classes: 'ui blue button date-picker'
    }, {
      action: 'selectDateRange',
      label: 'Clear',
      actionValue: [],
      classes: 'ui button date-picker'
    }],

    actions: {
      buildScreener: function buildScreener() {
        this.get('onScreen')(this.screener);
      },
      updateDateRange: function updateDateRange(dates) {
        if (dates.length == 1) {
          var start_date = dates[0].format('YYYY-MM-DD');
          this.set('screener.search.start_date', start_date);
          this.set('screener.search.end_date', null);
        } else if (dates.length == 2) {
          var _start_date = dates[0].format('YYYY-MM-DD');
          this.set('screener.search.start_date', _start_date);

          if (dates[1] != null) {
            var end_date = dates[1].format('YYYY-MM-DD');
            if (end_date == _start_date) {
              this.set('screener.search.end_date', null);
            } else {
              this.set('screener.search.end_date', end_date);
            }
          } else {
            this.set('screener.search.end_date', null);
          }
        } else if (dates.length == 0) {
          this.set('screener.search.start_date', null);
          this.set('screener.search.end_date', null);
        }
      },
      updatedWinPercentage: function updatedWinPercentage(values) {
        this.set('screener.filter.win_percentage.gte', values[0]);
        this.set('screener.filter.win_percentage.lte', values[1]);

        var screener = this.get('screener');

        this.set('winPercentageLabel', screener.filter.win_percentage.gte + '% to ' + screener.filter.win_percentage.lte + '%');
      }
    },

    defaults: {
      winPercentage: [80, 85, 90, 95, 100],
      patternDurations: [7, 10, 14, 21, 30, 35, 42, 49, 56, 63, 70, 77, 84, 91],
      patternHistory: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
      averageGainLoss: {
        range: [-100, -90, -80, -70, -60, -50, -40, -30, -20, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90]
      },
      securityPrice: [{
        value: 0,
        label: 'None'
      }, {
        value: 1,
        label: '$1'
      }, {
        value: 2,
        label: '$2'
      }, {
        value: 3,
        label: '$3'
      }, {
        value: 4,
        label: '$4'
      }, {
        value: 5,
        label: '$5'
      }, {
        value: 7.5,
        label: '$7.5'
      }, {
        value: 10,
        label: '$10'
      }, {
        value: 15,
        label: '$15'
      }, {
        value: 20,
        label: '$20'
      }, {
        value: 25,
        label: '$25'
      }, {
        value: 30,
        label: '$30'
      }, {
        value: 40,
        label: '$40'
      }, {
        value: 50,
        label: '$50'
      }, {
        value: 75,
        label: '$75'
      }, {
        value: 100,
        label: '$100'
      }, {
        value: 1000000,
        label: '$100+'
      }],
      exchanges: [{
        label: 'NYSE',
        value: 0
      }, {
        label: 'AMEX',
        value: 1
      }, {
        label: 'NASDAQ',
        value: 2
      }, {
        label: 'OTC',
        value: 3
      }],
      marketCap: [{
        label: 'All',
        value: 'all'
      }, {
        label: '10 Million',
        value: 10000000
      }, {
        label: '25 Million',
        value: 25000000
      }, {
        label: '50 Million',
        value: 50000000
      }, {
        label: '100 Million',
        value: 100000000
      }, {
        label: '250 Million',
        value: 250000000
      }, {
        label: '500 Million',
        value: 500000000
      }, {
        label: '1 Billion',
        value: 1000000000
      }, {
        label: '10 Billion',
        value: 10000000000
      }, {
        label: '25 Billion',
        value: 25000000000
      }, {
        label: '50 Billion',
        value: 50000000000
      }, {
        label: '100 Billion',
        value: 100000000000
      }],
      dailyVolume: [{
        label: 'None',
        value: 0
      }, {
        label: '10k',
        value: 10000
      }, {
        label: '25k',
        value: 25000
      }, {
        label: '50k',
        value: 50000
      }, {
        label: '100k',
        value: 100000
      }, {
        label: '250k',
        value: 250000
      }, {
        label: '500k',
        value: 500000
      }, {
        label: '1M',
        value: 1000000
      }, {
        label: '2M',
        value: 2000000
      }, {
        label: '5M',
        value: 5000000
      }, {
        label: '10M',
        value: 10000000
      }, {
        label: '10M+',
        value: 999999999999
      }],
      standardDeviation: [{
        value: 0,
        label: 'Any'
      }, {
        value: 1,
        label: '≤ 1%'
      }, {
        value: 2,
        label: '≤ 2%'
      }, {
        value: 3,
        label: '≤ 3%'
      }, {
        value: 4,
        label: '≤ 4%'
      }, {
        value: 5,
        label: '≤ 5%'
      }, {
        value: 6,
        label: '≤ 6%'
      }, {
        value: 7,
        label: '≤ 7%'
      }, {
        value: 8,
        label: '≤ 8%'
      }, {
        value: 9,
        label: '≤ 9%'
      }, {
        value: 10,
        label: '≤ 10%'
      }, {
        value: 11,
        label: '≤ 11%'
      }, {
        value: 12,
        label: '≤ 12%'
      }, {
        value: 13,
        label: '≤ 13%'
      }, {
        value: 14,
        label: '≤ 14%'
      }, {
        value: 15,
        label: '≤ 15%'
      }, {
        value: 20,
        label: '≤ 20%'
      }, {
        value: 25,
        label: '≤ 25%'
      }, {
        value: 30,
        label: '≤ 30%'
      }, {
        value: 35,
        label: '≤ 35%'
      }, {
        value: 40,
        label: '≤ 40%'
      }, {
        value: 45,
        label: '≤ 45%'
      }, {
        value: 50,
        label: '≤ 50%'
      }, {
        value: 55,
        label: '≤ 55%'
      }, {
        value: 60,
        label: '≤ 60%'
      }, {
        value: 65,
        label: '≤ 65%'
      }, {
        value: 70,
        label: '≤ 70%'
      }, {
        value: 80,
        label: '≤ 80%'
      }, {
        value: 90,
        label: '≤ 90%'
      }, {
        value: 100,
        label: '≤ 100%'
      }, {
        value: 1000,
        label: 'Average G/L ≥ 1.0 * Standard Deviation%'
      }, {
        value: 2000,
        label: 'Average G/L ≥ 2.0 * Standard Deviation%'
      }, {
        value: 3000,
        label: 'Average G/L ≥ 3.0 * Standard Deviation%'
      }, {
        value: 4000,
        label: 'Average G/L ≥ 4.0 * Standard Deviation%'
      }, {
        value: 5000,
        label: 'Average G/L ≥ 5.0 * Standard Deviation%'
      }, {
        value: 7500,
        label: 'Average G/L ≥ 7.5.0 * Standard Deviation%'
      }, {
        value: 10000,
        label: 'Average G/L ≥ 10.0 * Standard Deviation%'
      }],
      start: [5, 10],
      range: {
        min: 5,
        max: 50
      }
    }
  });
});
