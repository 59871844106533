define("seasonalysis/components/modals/security-lookup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    value: "",
    loading: false,

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var shouldClearText = this.get("shouldClearText");
      if (shouldClearText == true) {
        this.set("value", "");
        this.set("results", null);
      }
    },


    actions: {
      onClose: function onClose() {
        console.log("Closing modal");
        this.get("onClose")();
        this.set("show", false);
      },
      handleSecurityLookup: function handleSecurityLookup() {
        var _this = this;

        var filterInputValue = this.get("value");
        this.set("loading", true);

        return Ember.run.debounce(this, function () {
          return _this.get("store").query("security", { search: filterInputValue }).then(function (results) {
            _this.set("loading", false);
            return _this.set("results", results);
          });
        }, 500);
      },
      addSecurity: function addSecurity(security) {
        console.log("addSecurity", security);
        this.get("onAdd")(security);
      },
      removeSecurity: function removeSecurity(security) {
        console.log("removeSecurity", security);
        this.get("onRemove")(security);
      },
      securityClicked: function securityClicked(security) {
        console.log("securityClicked", security.id);
        this.set("results", null);
        this.set("value", "");
        this.get("router").transitionTo("security-detail", security.id);
      },
      clearResults: function clearResults() {
        this.set("results", null);
        this.set("value", "");
      }
    }
  });
});
