define("seasonalysis/serializers/fragment", ["exports", "ember-data/serializers/json"], function (exports, _json) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    keyForAttribute: function keyForAttribute(key) {
      // From http://emberjs.com/api/classes/Ember.String.html#method_underscore
      return Ember.String.underscore(key);
    }
  });
});
